// Create a file named globalMixins.js
import Vue from 'vue';

Vue.mixin({
  computed: {
    alignRightInArabic() {
      return {
        textAlign: this.$i18n.locale === 'ar' ? 'right' : 'left',
      }
    },
    justifyInArabic() {
      if (this.$i18n.locale === 'ar') {
        return {
          textAlign: 'justify'
        };
      } else {
        return {}; // Return an empty object
      }
    },
  },
});
