import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import './globalComputed';

import VueI18n from "vue-i18n";
import messages from "./messages";
Vue.use(VueI18n);
const savedLanguage = localStorage.getItem('language_option') || 'en';
const i18n = new VueI18n({
  locale: savedLanguage,
  messages,
});

/* asset imports */
import './assets/scss/main.scss';

import { gsap } from 'gsap';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
