<template>
  <header class="header">
    <nav class="navbar navbar-expand-lg fixed-top" v-bind:class="{
      affix: hasAffix,
      'custom-nav': coloredLogo,
      'bg-transparent': !coloredLogo,
    }">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img :src="coloredLogo
            ? '../../assets/img/logo.png'
            : '../../assets/img/logo.png'
            " alt="logo" class="img-fluid" width="150" height="50" />
        </a>
        <button class="navbar-toggler" type="button" @click="mobileNavClicked" v-bind:class="{ collapsed: collapsed }">
          <span class="ti-menu"></span>
        </button>
        <div class="collapse navbar-collapse h-auto" v-bind:class="{ show: !collapsed }" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto menu">
            <li>
              <a href="/">{{ $t("Home") }}</a>
            </li>
            <li>
              <a href="/#services">{{ $t("Services") }}</a>
            </li>
            <li>
              <a href="/#Clients">{{ $t("Clients") }}</a>
            </li>
            <li>
              <a href="/#partners">{{ $t("Partners") }}</a>
            </li>
            <li>
              <a href="/#about">{{ $t("About") }}</a>
            </li>
            <li>
              <a href="/#contact">{{ $t("Contact") }}</a>
            </li>

            <li>
              <a v-if="$i18n.locale !== 'ar'"><span class="flag-icon flag-icon-us"></span> English</a>
              <a v-else><span class="flag-icon flag-icon-sa"></span>العربية</a>
              <ul class="sub-menu" style="cursor: pointer;">
                <li>
                  <a @click="$i18n.locale = 'en'"><span class="flag-icon flag-icon-us"></span> English</a>
                </li>
                <li>
                  <a @click="$i18n.locale = 'ar'"><span class="flag-icon flag-icon-sa"></span>العربية</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>

<style>
.select-style {
  background-color: transparent;
  border: none;
  font-size: 14px;
  text-decoration: none;
  padding: 22px 12px;
  transition: all 0.3s ease-in-out 0s;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}
</style>