import Vue from 'vue';
import VueRouter from 'vue-router';

import Index from '../pages/indices/Index.vue';

// import Page404 from '../pages/utilities/Page404';
// import ComingSoonPage from '../pages/utilities/ComingSoonPage';
// import ThankYouPage from '../pages/utilities/ThankYouPage.vue';

import AIBasedCustomSolutions from '../pages/ServiceDetails/AIBasedCustomSolutions';
import AppModernizationServices from '../pages/ServiceDetails/AppModernizationServices';
import DevOpsServices from '../pages/ServiceDetails/DevOpsServices';
import MinIOServicesInSaudiArabia from '../pages/ServiceDetails/MinIOServicesInSaudiArabia';
import MicrosoftAuthorizedServiceProvider from '../pages/ServiceDetails/MicrosoftAuthorizedServiceProvider';
import AppContainerizationServices from '../pages/ServiceDetails/AppContainerizationServices';
import KubernetesServicesAndSupport from '../pages/ServiceDetails/KubernetesServicesAndSupport';
import DevSecOpsBestPracticesByMicroBeaverForITSolutions from '../pages/ServiceDetails/DevSecOpsBestPracticesByMicroBeaverForITSolutions';
import KongAPIGatewaySupportByMicroBeaverForITSolutions from '../pages/ServiceDetails/KongAPIGatewaySupportByMicroBeaverForITSolutions';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: { title: 'Micro Beaver' }
    },
    {
        path: '/ServiceDetails/AI-Based-Custom-Solutions',
        name: 'AIBasedCustomSolutions',
        component: AIBasedCustomSolutions,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/App-Modernization-Services',
        name: 'AppModernizationServices',
        component: AppModernizationServices,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/DevOps-Services',
        name: 'DevOpsServices',
        component: DevOpsServices,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/MinIO-Services-in-Saudi-Arabia',
        name: 'MinIOServicesInSaudiArabia',
        component: MinIOServicesInSaudiArabia,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/Microsoft-Authorized-Service-Provider',
        name: 'MicrosoftAuthorizedServiceProvider',
        component: MicrosoftAuthorizedServiceProvider,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/App-Containerization-Services',
        name: 'AppContainerizationServices',
        component: AppContainerizationServices,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/Kubernetes-Services-and-Support',
        name: 'KubernetesServicesAndSupport',
        component: KubernetesServicesAndSupport,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/DevSecOps-Best-Practices-by-Micro-Beaver-for-IT-Solutions',
        name: 'DevSecOpsBestPracticesByMicroBeaverForITSolutions',
        component: DevSecOpsBestPracticesByMicroBeaverForITSolutions,
        meta: { anchor: 'contact', cashable: true }
    },
    {
        path: '/ServiceDetails/Kong-API-Gateway-Support-by-Micro-Beaver-for-IT-Solutions',
        name: 'KongAPIGatewaySupportByMicroBeaverForITSolutions',
        component: KongAPIGatewaySupportByMicroBeaverForITSolutions,
        meta: { anchor: 'contact', cashable: true }
    },
    // {
    //     path: '/404',
    //     name: '404',
    //     component: Page404,
    //     meta: { title: 'Not Found - Apdash - App Landing Page Template', cashable: true }
    // },
    // {
    //     path: '/coming-soon',
    //     name: 'coming-soon',
    //     component: ComingSoonPage,
    //     meta: { title: 'Coming Soon - Apdash - App Landing Page Template', cashable: true }
    // },
    // {
    //     path: '/thank-you-page',
    //     name: 'thank-you-page',
    //     component: ThankYouPage,
    //     meta: { title: 'Coming Soon - Apdash - App Landing Page Template', cashable: true }
    // },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            const element = document.getElementById(to.meta.anchor);
            if (element) {
                return window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title)
        document.title = to.meta.title;
    next();
});


export default router;
