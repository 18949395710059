<template>
    <div>
        <nav-bar :colored-logo="true" />
        <div class="main">
            <banner />
            <why-microBeaver />
            <services :is-gray="true" />
            <our-mission />
            <work-process :is-gray="true" />
            <clients />
            <about-us :is-gray="true" />
            <contact />
            <Partners :is-gray="true" />
        </div>
        <site-footer :show-subscribe="true" />
        <copyright />
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar.vue";
import Banner from "../../views/index/MainBanner.vue";
import AboutUs from "../../views/commons/sections/AboutUs.vue";
import WhyMicroBeaver from "../../views/index/WhyMicroBeaver.vue";
import Services from "../../views/index/Services.vue";
import OurMission from "../../views/index/OurMission.vue";
import WorkProcess from "../../views/commons/sections/WorkProcess.vue";
import SiteFooter from "../../views/commons/SiteFooter.vue";
import Copyright from "../../views/commons/Copyright.vue";
import Contact from "../../views/index/Contact.vue";
import Clients from "../../views/commons/sections/Clients.vue";
import Partners from "../../views/commons/sections/Partners.vue";

export default {
    name: "Index",
    components: {
        NavBar,
        Banner,
        WhyMicroBeaver,
        AboutUs,
        Services,
        OurMission,
        WorkProcess,
        SiteFooter,
        Copyright,
        Contact,
        Clients,
        Partners,
    },
};
</script>