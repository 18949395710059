<template>
    <section id="process" class="work-process-section position-relative" v-bind:class="{
        'gray-light-bg': isGray,
        'pb-100': paddingBottom,
        'pt-100': paddingTop,
    }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-10">
                    <div class="section-heading text-center mb-5">
                        <h2>“{{ $t("Our_Services_Approach") }}”</h2>
                        <p>
                            {{ $t("Our_Services_Approach_Explanation") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center justify-content-md-center justify-content-sm-center">
                <div class="col-md-12 col-lg-6">
                    <div class="work-process-wrap">
                        <div class="process-single-item">
                            <div class="process-icon-item left-shape">
                                <div class="d-flex align-items-center">
                                    <div class="process-icon mr-4">
                                        <i class="fas fa-project-diagram" style="color: #74caed"></i>
                                    </div>
                                    <div class="process-content text-left">
                                        <h5 :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">{{
                                                $t("Application_Modernization") }}</h5>
                                        <p :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                                            {{ $t("Application_Modernization_Explanation") }}
                                        </p>
                                    </div>
                                </div>
                                <svg x="0px" y="0px" width="312px" height="130px">
                                    <path class="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1"
                                        stroke-dasharray="1300" stroke-dashoffset="0"
                                        d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338">
                                    </path>
                                    <path class="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                        stroke-dasharray="6" stroke-dashoffset="1300"
                                        d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 ">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="process-single-item">
                            <div class="process-icon-item right-shape">
                                <div class="d-flex align-items-center">
                                    <div class="process-icon ml-4">
                                        <i class="fas fa-puzzle-piece" style="color: #274d9e"></i>
                                    </div>
                                    <div class="process-content text-right">
                                        <h5 :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">{{
                                                $t("DevOps_Services_Our_Services_Approach") }}</h5>
                                        <p :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                                            {{ $t("DevOps_Services_Our_Services_Approach_Explanation") }}
                                        </p>
                                    </div>
                                </div>
                                <svg x="0px" y="0px" width="312px" height="130px">
                                    <path class="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1"
                                        stroke-dasharray="1300" stroke-dashoffset="0"
                                        d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996">
                                    </path>
                                    <path class="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                        stroke-dasharray="6" stroke-dashoffset="1300"
                                        d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="process-single-item">
                            <div class="process-icon-item left-shape mb-0">
                                <div class="d-flex align-items-center">
                                    <div class="process-icon mr-4">
                                        <i class="fas fa-truck" style="color: #74caed"></i>
                                    </div>
                                    <div class="process-content text-left">
                                        <h5 :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">{{ $t("Kubernetes_Services") }}
                                        </h5>
                                        <p :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                                            {{ $t("Kubernetes_Services_Explanation") }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="img-wrap">
                        <img src="../../../assets/img/work-process.png" alt="features" class="img-fluid process-img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WorkSection",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        paddingTop: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style>
.process-img {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>