<template>
    <div>
        <nav-bar :colored-logo="true" />

        <div class="main">
            <section class="page-header-section ptb-100 page-header-bg bg-image" image-overlay="8" style="opacity: 1">
                <div class="background-image-wraper page-header-bg"></div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-8" :style="{ ...alignRightInArabic }"
                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                            <div class="page-header-content text-white pt-100">
                                <span class="text-white mb-0 h1 font-weight-bold">
                                    {{ $t("App_Modernization_Services") }}
                                </span>
                                <p class="lead" style="font-size: medium; line-height: 18px">
                                    {{ $t("App_Modernization_Services_Explanation") }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="page-header-content text-white pt-100">
                                <img src="../../assets/img/App Modernization Services.svg" alt="app icon" width="150"
                                    class="img-fluid rounded mx-auto d-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container ptb-100">
                <div class="row justify-content-center" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-10">
                        <p class="text-justify" :style="{ ...alignRightInArabic }"
                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                            {{ $t("App_Modernization_Services_details_01") }}
                            <br /><br />
                            {{ $t("App_Modernization_Services_details_02") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <site-footer />
        <copyright />
    </div>
</template>

<script>
// import PageHeader from "../../views/commons/PageHeader.vue";
import NavBar from "../../views/commons/NavBar.vue";
import SiteFooter from "../../views/commons/SiteFooter";
import Copyright from "../../views/commons/Copyright.vue";

export default {
    name: "AppModernizationServices",
    components: {
        NavBar,
        SiteFooter,
        Copyright,
        // PageHeader,
    },
    data: function () {
        return {
            // ServiceName: decodeURIComponent(this.$route.params.ServiceName),
        };
    },
};
</script>