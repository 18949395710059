var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg fixed-top",class:{
    affix: _vm.hasAffix,
    'custom-nav': _vm.coloredLogo,
    'bg-transparent': !_vm.coloredLogo,
  }},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.coloredLogo
          ? '../../assets/img/logo.png'
          : '../../assets/img/logo.png',"alt":"logo","width":"150","height":"50"}})]),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto menu"},[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.$t("Home")))])]),_c('li',[_c('a',{attrs:{"href":"/#services"}},[_vm._v(_vm._s(_vm.$t("Services")))])]),_c('li',[_c('a',{attrs:{"href":"/#Clients"}},[_vm._v(_vm._s(_vm.$t("Clients")))])]),_c('li',[_c('a',{attrs:{"href":"/#partners"}},[_vm._v(_vm._s(_vm.$t("Partners")))])]),_c('li',[_c('a',{attrs:{"href":"/#about"}},[_vm._v(_vm._s(_vm.$t("About")))])]),_c('li',[_c('a',{attrs:{"href":"/#contact"}},[_vm._v(_vm._s(_vm.$t("Contact")))])]),_c('li',[(_vm.$i18n.locale !== 'ar')?_c('a',[_c('span',{staticClass:"flag-icon flag-icon-us"}),_vm._v(" English")]):_c('a',[_c('span',{staticClass:"flag-icon flag-icon-sa"}),_vm._v("العربية")]),_c('ul',{staticClass:"sub-menu",staticStyle:{"cursor":"pointer"}},[_c('li',[_c('a',{on:{"click":function($event){_vm.$i18n.locale = 'en'}}},[_c('span',{staticClass:"flag-icon flag-icon-us"}),_vm._v(" English")])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$i18n.locale = 'ar'}}},[_c('span',{staticClass:"flag-icon flag-icon-sa"}),_vm._v("العربية")])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }