<template>
  <section id="contact" class="contact-us-section ptb-100" v-bind:class="{ 'gray-light-bg': isGray }">
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-12 pb-3 message-box" v-bind:class="{
          'd-none': !isSuccess && !hasError,
          'd-block': isSuccess || hasError,
        }">
          <div class="alert" v-bind:class="{
            'alert-danger': hasError,
            'alert-success': isSuccess,
          }">
            {{ alertText }}
          </div>
        </div>

        <div class="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
          <div class="contact-us-form gray-light-bg rounded p-5">
            <h4 :class="{ rtl: this.$i18n.locale == 'ar' }" :style="{ ...alignRightInArabic, ...justifyInArabic }">
              {{ $t("Ready_to_get_started") }} {{ $t("QuestionMark") }}
            </h4>
            <form ref="contactForm" class="contact-us-form">
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" name="name" id="nameInput" :placeholder="$t('Enter_name')"
                      required="required" v-model="formData.name" :class="{ rtl: this.$i18n.locale == 'ar' }"
                      :style="{ ...alignRightInArabic, ...justifyInArabic }" />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" id="emailInput"
                      :placeholder="$t('Enter_email')" required="required" v-model="formData.email"
                      :class="{ rtl: this.$i18n.locale == 'ar' }"
                      :style="{ ...alignRightInArabic, ...justifyInArabic }" />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <textarea name="message" id="message" class="form-control" rows="7" cols="25"
                      :placeholder="$t('Message')" v-model="formData.message"
                      :class="{ rtl: this.$i18n.locale == 'ar' }"
                      :style="{ ...alignRightInArabic, ...justifyInArabic }"></textarea>
                  </div>
                </div>

                <div class="col-12" :class="{ rtl: this.$i18n.locale == 'ar' }"
                  :style="{ ...alignRightInArabic, ...justifyInArabic }">
                  <!-- ----- here start vue-recaptcha ----- -->
                  <VueRecaptcha ref="reCAPTCHAField" @verify="onCaptchaVerify" :sitekey="yourRecaptchaSiteKey">
                  </VueRecaptcha>
                  <!-- ----- here end vue-recaptcha ----- -->
                </div>

                <div class="col-sm-12 mt-3" :class="{ rtl: this.$i18n.locale == 'ar' }"
                  :style="{ ...alignRightInArabic, ...justifyInArabic }">
                  <button :disabled="isButtonDisabled" type="button" @click="sendEmail()" id="submitBtn"
                    class="btn btn-brand-02">
                    <strong> {{ $t("Send_Message") }}</strong>
                    <span id="spinnerHS" class="spinner-border spinner-border-sm d-none mr-1 ml-1" role="status"
                      aria-hidden="true"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12 col-lg-7">
          <div class="contact-us-content">
            <h2 style="color: #74caed" :class="{ rtl: this.$i18n.locale == 'ar' }"
              :style="{ ...alignRightInArabic, ...justifyInArabic }">
              {{ $t("Ready_for_the_change") }} {{ $t("QuestionMark") }}
            </h2>
            <p class="lead" :class="{ rtl: this.$i18n.locale == 'ar' }"
              :style="{ ...alignRightInArabic, ...justifyInArabic }">
              {{ $t("Ready_for_the_change_explanation") }}
            </p>
            <hr class="my-5" />
            <ul class="contact-info-list" :class="{ rtl: this.$i18n.locale == 'ar' }"
              :style="{ ...alignRightInArabic, ...justifyInArabic }">
              <li class="d-flex pb-3">
                <div class="contact-icon mr-3 ml-3">
                  <span class="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                </div>
                <div class="contact-text">
                  <h5 class="mb-1">{{ $t("Company_Location") }}</h5>
                  <p>{{ $t("Company_Location_Riyadh") }}</p>
                </div>
              </li>
              <li class="d-flex pb-3">
                <div class="contact-icon mr-3 ml-3">
                  <span class="fas fa-envelope color-primary rounded-circle p-3"></span>
                </div>
                <div class="contact-text">
                  <h5 class="mb-1">{{ $t("Email_Address") }}</h5>
                  <p>info@microbeaver.com</p>
                </div>
              </li>
              <li class="d-flex pb-3">
                <div class="contact-icon mr-3 ml-3">
                  <span class="fas fa-phone color-primary rounded-circle p-3"></span>
                </div>
                <div class="contact-text">
                  <h5 class="mb-1">{{ $t("Phone_Number") }}</h5>
                  <p>{{ $t("Phone_Number_Phone") }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import emailjs from "emailjs-com";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Contact",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      ThxFormData: {
        name: "",
        email: "",
        message: "",
      },
      reCAPTCHAresponse: false,

      // errors: {
      //   name: "",
      //   email: "",
      //   message: "",
      // },

      yourRecaptchaSiteKey: "6Lfisy8pAAAAAPrCOKF66stQ7p9Onfle528YJnmr",

      to: "abdullah.al.hallak99@gmail.com", // recipient's email
      subject: "",

      // mailtoLink: 'mailto:abdullah.al.hallak99@gmail.com?subject=Feedback&body=Hello%20there!',
      alertText: "",
      hasError: false,
      isSuccess: false,
    };
  },
  computed: {
    isButtonDisabled() {
      return !(
        this.formData.name &&
        this.formData.email &&
        this.formData.message &&
        this.reCAPTCHAresponse
      );
    },
  },
  methods: {
    onCaptchaVerify(response) {
      // This method will be called when the user successfully completes the reCAPTCHA challenge.
      if (response) {
        this.reCAPTCHAresponse = true;
      }
      console.log("Captcha verified. Response:", response);
      console.log("reCAPTCHAresponse:", this.reCAPTCHAresponse);
    },
    sendEmail() {
      const VarSpinnerHideShow = document.getElementById("spinnerHS");
      VarSpinnerHideShow.classList.remove("d-none");

      const templateParams = {
        email_id: this.formData.email,
        from_name: this.formData.name,
        message: this.formData.message,
      };

      this.ThxFormData.email = this.formData.email;
      this.ThxFormData.name = this.formData.name;
      this.ThxFormData.message = this.formData.message;

      emailjs
        .send(
          "service_nhwwnv8",
          "template_vqyo90i",
          templateParams,
          "9d7nM5AoKMKEnxnz3"
        )
        .then(
          (response) => {
            // Handle success
            console.log("Email sent successfully:", response);
            console.log("templateParams:", templateParams);

            // Send thank-you email
            this.sendThankYouEmail(templateParams.email_id, templateParams.from_name);

            this.formData.email = "";
            this.formData.message = "";
            this.formData.name = "";
            this.reCAPTCHAresponse = false;

            // Show success message
            this.alertText = "Form submitted successfully";
            this.hasError = false;
            this.isSuccess = true;

            // Hide spinner
            VarSpinnerHideShow.classList.add("d-none");

            // Reset form and reCAPTCHA
            this.$refs.contactForm.reset();
            this.$refs.reCAPTCHAField.reset();
          },
          (error) => {
            // Handle error
            console.error("Failed to send email:", error);

            this.alertText = "Found error in the form. Please check again.";
            this.isSuccess = false;
            this.hasError = true;

            VarSpinnerHideShow.classList.remove("d-none");
          }
        );
    },
    sendThankYouEmail(email, name) {
      const thankYouParams = {
        email_id: email,
        from_name: name,
      };
      emailjs
        .send(
          "service_nhwwnv8", // Use the same service ID or create a new one
          "template_m1x0ygd", // Create a new template for the thank-you email
          thankYouParams,
          "9d7nM5AoKMKEnxnz3" // Your public key
        )
        .then(
          (response) => {
            console.log("Thank you email sent successfully:", response);
          },
          (error) => {
            console.error("Failed to send thank you email:", error);
          }
        );
    },
  },
  mounted() {
    this.alertText = "";
    this.hasError = false;
    this.isSuccess = false;
  },
  // },
};
</script>