<template>
    <div class="footer-bottom py-3 gray-light-bg">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="copyright-wrap small-text text-center">
                        <p class="mb-0" :class="{ rtl: this.$i18n.locale == 'ar' }"
                            :style="{ ...alignRightInArabic, textAlign: 'center' }">
                            &copy; {{ currentYear }} {{ $t("Copyright") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Copyright",
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>