<template>
    <section class="position-relative feature-section ptb-100" v-bind:class="{ 'gray-light-bg': isGray }">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-12 col-lg-9">
                    <div class="feature-contents section-heading">
                        <h2 class="font-weight-bold" :style="{ ...alignRightInArabic, color: '#29ade3' }"
                            :class="{ rtl: this.$i18n.locale == 'ar' }">
                            {{ $t("OurMissionAtMicroBeaver") }}
                            <span style="color: #274d9e">{{ $t("MicroBeaver") }}</span>:
                        </h2>

                        <p :style="alignRightInArabic" :class="{ rtl: this.$i18n.locale == 'ar' }">
                            {{ $t("OurMissionAtMicroBeaverExplanation") }}
                        </p>

                        <ul :class="{ rtl: this.$i18n.locale == 'ar', 'pt-2': true }"
                            :style="{ ...alignRightInArabic, ...justifyInArabic }">
                            <li>
                                <span class="bullet" style="color: #29ade3;">&#8226;</span> <!-- Bullet point -->
                                <strong> {{ $t("Accelerating_DevOps_Excellence") }} </strong>
                                {{ $t("Accelerating_DevOps_Excellence_One_Bullet") }}
                            </li>
                            <li class="mt-4">
                                <span class="bullet" style="color: #29ade3;">&#8226;</span> <!-- Bullet point -->
                                <strong> {{ $t("Revolutionizing_Application_Landscapes") }} </strong>
                                {{ $t("Revolutionizing_Application_Landscapes_Two_Bullet") }}
                            </li>
                            <li class="mt-4">
                                <span class="bullet" style="color: #29ade3;">&#8226;</span> <!-- Bullet point -->
                                <strong> {{ $t("National_Digital_Transformation") }} </strong>
                                {{ $t("National_Digital_Transformation_Three_Bullet") }}
                            </li>
                            <li class="mt-4">
                                <span class="bullet" style="color: #29ade3;">&#8226;</span> <!-- Bullet point -->
                                <strong> {{ $t("Saudi_Talent_Development") }} </strong>
                                {{ $t("Saudi_Talent_Development_Four_Bullet") }}
                            </li>
                            <li class="mt-4">
                                <span class="bullet" style="color: #29ade3;">&#8226;</span> <!-- Bullet point -->
                                <strong> {{ $t("Sustainable_Innovation") }} </strong>
                                {{ $t("Sustainable_Innovation_Five_Bullet") }}
                            </li>
                        </ul>

                        <p :class="{ rtl: this.$i18n.locale == 'ar' }"
                            :style="{ ...alignRightInArabic, ...justifyInArabic }">
                            {{ $t("OurMissionAtMicroBeaverCaption") }}
                        </p>
                    </div>
                </div>

                <div class="col-md-12 col-lg-3">
                    <div class="download-img">
                        <img src="../../assets/img/logo2.png" alt="download" class="img-fluid mx-auto" width="175"
                            style="display: block;">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurVision',
    props: {
        isGray: {
            type: Boolean,
            default: false
        }
    }
}
</script>