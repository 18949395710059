<template>
  <section id="Clients" class="team-two-section ptb-100" v-bind:class="{ 'gray-light-bg': isGray }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-8">
          <div class="section-heading text-center">
            <h2 :class="{ rtl: this.$i18n.locale == 'ar' }">{{
              $t("Our_Clients") }}</h2>
            <p :class="{ rtl: this.$i18n.locale == 'ar' }">
              {{ $t("Our_Clients_Explanation") }}
            </p>
          </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center">
        <div v-for="member in members" v-bind:key="member.imageUrl" class="col-md-6 col-lg-4">
          <team-member :name="member.name" :post="member.post" :desc="member.desc" :image-url="member.imageUrl"
            :link="member.link" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamMember from "../../../components/TeamMember";
export default {
  name: "Clients",
  components: { TeamMember },
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      members: [
        {
          name: "Ministry of Media",
          post: "The Ministry of Media of Saudi Arabia",
          imageUrl: "../assets/img/client/ministry.png",
          link: "https://media.gov.sa/",
        },
        {
          name: "GAMR",
          post: "الهيئة العامة لتنظيم الإعلام",
          imageUrl: "../assets/img/client/GAMR.png",
          link: "https://gamr.gov.sa/",
        },
        {
          name: "SaudiPedia",
          post: "سعوديبيديا | موسوعة سعودية رقمية متعددة اللغات",
          imageUrl: "../assets/img/client/gamrsudia.svg",
          link: "https://saudipedia.com/",
        },
      ],
    };
  },
};
</script>

<style></style>