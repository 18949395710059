<template>
  <!-- <div :id="'#' + $t('services')" class="feature-section ptb-100" :class="{ 'gray-light-bg': isGray }"> -->
  <div id="services" class="feature-section ptb-100" :class="{ 'gray-light-bg': isGray }"
    :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-9">
          <div class="section-heading text-center mb-5">
            <h2>{{ $t("OurProfessionalServices") }}</h2>
            <p :class="{ rtl: this.$i18n.locale == 'ar' }">
              {{ $t("OurProfessionalServicesExplanation") }}
            </p>
          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-md-center">
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/AI-Based-Custom-Solutions.webp" alt="app icon" width="50"
                  class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <a class="mb-2 h5" style="cursor: pointer;" @click="GoToService01()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("AI_Based_Custom_Solutions") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("AI_Based_Custom_Solutions_Explanation") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/App-Modernization-Services_1.webp" alt="app icon" width="50"
                  class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService02()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("App_Modernization_Services") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("App_Modernization_Services_Explanation") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/7076137.webp" alt="app icon" width="50" class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">DevSecOps services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService03()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("DevOps_Services") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("DevOps_Services_Explanation") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/MinIO-Services.webp" alt="app icon" width="50" class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">DevSecOps services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService04()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("MinIO_Services_in_Saudi_Arabia") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("MinIO_Services_in_Saudi_Arabia_Explanation") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/Microsoft-Authorized-Service-Provider.webp" alt="app icon" width="50"
                  class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">DevSecOps services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService05()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("Microsoft_Authorized_Service_Provider") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("Microsoft_Authorized_Service_Provider_Explanation") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
          <div class="position-relative pb-md-5 py-lg-0">
            <img alt="Image placeholder" src="../../../public/assets/img/2094394FD.webp" class="img-center img-fluid"
              height="613" width="407" />
          </div>
        </div>

        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/App-Containerization-Services.webp" alt="app icon" width="50"
                  class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">Containerization</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService06()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("App_Containerization_Services") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("App_Containerization_Services_Explanation") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/Kubernetes-Services-logo.webp" alt="app icon" width="50"
                  class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">Kubernetes services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService07()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("Kubernetes_Services_and_Support") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("Kubernetes_Services_and_Support_Explanation") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/DevSecOps Best Practices by Micro Beaver for IT Solutions.svg" alt="app icon"
                  width="50" class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">Kubernetes services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService08()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">
                    {{ $t("DevSecOps_Best_Practices_by_Micro_Beaver_for_IT_Solutions") }}
                  </a>
                  <p :style="justifyInArabic">
                    {{ $t("DevSecOps_Best_Practices_by_Micro_Beaver_for_IT_Solutions_Explanation") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12" :style="alignRightInArabic">
              <div class="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                <img src="../../assets/img/Kong API Gateway Support by Micro Beaver for IT Solutions.png" alt="app icon"
                  width="50" class="img-fluid mr-3 ml-3" />
                <div class="icon-text">
                  <!-- <h5 class="mb-2">Kubernetes services</h5> -->
                  <a class="mb-2 h5" style="cursor: pointer" @click="GoToService09()"
                    :class="{ rtl: this.$i18n.locale == 'ar' }">{{
                      $t("Kong_API_Gateway_Support_by_Micro_Beaver_for_IT_Solutions") }}</a>
                  <p :style="justifyInArabic">
                    {{ $t("Kong_API_Gateway_Support_by_Micro_Beaver_for_IT_Solutions_Explanation") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data: () => ({
    // HeadingOfService01: "test",
  }),
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    GoToService01() {
      this.$router.push("/ServiceDetails/AI-Based-Custom-Solutions");
    },
    GoToService02() {
      this.$router.push("/ServiceDetails/App-Modernization-Services");
    },
    GoToService03() {
      this.$router.push("/ServiceDetails/DevOps-Services");
    },
    GoToService04() {
      this.$router.push("/ServiceDetails/MinIO-Services-in-Saudi-Arabia");
    },
    GoToService05() {
      this.$router.push("/ServiceDetails/Microsoft-Authorized-Service-Provider");
    },
    GoToService06() {
      this.$router.push("/ServiceDetails/App-Containerization-Services");
    },
    GoToService07() {
      this.$router.push("/ServiceDetails/Kubernetes-Services-and-Support");
    },
    GoToService08() {
      this.$router.push("/ServiceDetails/DevSecOps-Best-Practices-by-Micro-Beaver-for-IT-Solutions");
    },
    GoToService09() {
      this.$router.push("/ServiceDetails/Kong-API-Gateway-Support-by-Micro-Beaver-for-IT-Solutions");
    },
  },
};
</script>
