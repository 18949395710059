<template>
  <!--hero section start-->
  <section class="position-relative overflow-hidden hero-section-3 ptb-100">
    <!--animated circle shape start-->
    <div class="animated-shape-wrap">
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
    </div>
    <!--animated circle shape end-->

    <img src="../../assets/img/combined-shape.svg" alt="s" class="shape-img-2" />
    <img src="../../assets/img/shape-2.svg" alt="group shape" width="60" class="img-fluid group-shape-2" />

    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="hero-content-left pt-5">
            <span>
              <span class="font-weight-bold display-4" style="color: #274d9e">Modernization</span>

              <br />

              <p class="lead" style="font-size: 2rem; line-height: 30px">
                Isn't a choice, it's a necessity.
              </p>
            </span>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="img-wrap">
            <img src="../../assets/img/heroImg.webp" alt="hero" class="img-fluid mt-3" />
          </div>
        </div>
      </div>
    </div>

    <!--shape image start-->
    <img src="../../assets/img/hero-bg-shape-4.svg" class="shape-image" alt="shape image" />
    <!--shape image end-->
  </section>
</template>

<script>
export default {
  name: "MainBanner",
  data() {
    return {
    };
  },
};
</script>