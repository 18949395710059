<template>
  <footer class="footer-1 gradient-bg ptb-60" v-bind:class="{ 'footer-with-newsletter': showSubscribe }">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-3 mb-4 mb-md-4 mb-sm-4 mb-lg-0 d-flex-center">
          <a href="#" class="navbar-brand mb-2">
            <img src="../../assets/img/logoo2.png" alt="logo" class="img-fluid" width="220" height="65" />
          </a>
        </div>
        <div class="col-md-12 col-lg-9">
          <div class="row mt-0">
            <div class="col-sm-6 col-md-5 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
              <h6 class="text-uppercase" :class="{ rtl: this.$i18n.locale == 'ar' }"
                :style="{ ...alignRightInArabic, ...justifyInArabic }">{{ $t("SERVICES") }}</h6>
              <ul :class="{ rtl: this.$i18n.locale == 'ar' }" :style="{ ...alignRightInArabic, ...justifyInArabic }">
                <li>
                  <a style="cursor: pointer" @click="GoToService01()">{{ $t("AI_Based_Custom_Solutions") }}</a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService02()">{{ $t("App_Modernization_Services") }}</a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService03()">{{ $t("DevOps_Services") }}</a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService04()">{{ $t("MinIO_Services_in_Saudi_Arabia") }}</a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService05()">
                    {{ $t("Microsoft_Authorized_Service_Provider") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-5 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
              <h6 class="text-uppercase"></h6>
              <ul :class="{ rtl: this.$i18n.locale == 'ar' }" :style="{ ...alignRightInArabic, ...justifyInArabic }">
                <li>
                  <a style="cursor: pointer" @click="GoToService06()">
                    {{ $t("App_Containerization_Services") }}
                  </a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService07()">
                    {{ $t("Kubernetes_Services_and_Support") }}
                  </a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService08()">
                    {{ $t("DevSecOps_Best_Practices_by_Micro_Beaver_for_IT_Solutions") }}
                  </a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="GoToService09()">
                    {{ $t("Kong_API_Gateway_Support_by_Micro_Beaver_for_IT_Solutions") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-2 col-lg-2">
              <h6 class="text-uppercase" :class="{ rtl: this.$i18n.locale == 'ar' }"
                :style="{ ...alignRightInArabic, ...justifyInArabic }">{{ $t("SUPPORT") }}</h6>
              <ul :class="{ rtl: this.$i18n.locale == 'ar' }" :style="{ ...alignRightInArabic, ...justifyInArabic }">
                <li>
                  <a href="/">{{ $t("Home") }}</a>
                </li>
                <li>
                  <a href="/#services">{{ $t("Services") }}</a>
                </li>
                <li>
                  <a href="/#Clients">{{ $t("Clients") }}</a>
                </li>
                <li>
                  <a href="/#partners">{{ $t("Partners") }}</a>
                </li>
                <li>
                  <a href="/#about">{{ $t("About") }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end of container-->
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  props: {
    showSubscribe: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    GoToService01() {
      this.$router.push("/ServiceDetails/AI-Based-Custom-Solutions");
    },
    GoToService02() {
      this.$router.push("/ServiceDetails/App-Modernization-Services");
    },
    GoToService03() {
      this.$router.push("/ServiceDetails/DevOps-Services");
    },
    GoToService04() {
      this.$router.push("/ServiceDetails/MinIO-Services-in-Saudi-Arabia");
    },
    GoToService05() {
      this.$router.push(
        "/ServiceDetails/Microsoft-Authorized-Service-Provider"
      );
    },
    GoToService06() {
      this.$router.push("/ServiceDetails/App-Containerization-Services");
    },
    GoToService07() {
      this.$router.push("/ServiceDetails/Kubernetes-Services-and-Support");
    },
    GoToService08() {
      this.$router.push(
        "/ServiceDetails/DevSecOps-Best-Practices-by-Micro-Beaver-for-IT-Solutions"
      );
    },
    GoToService09() {
      this.$router.push(
        "/ServiceDetails/Kong-API-Gateway-Support-by-Micro-Beaver-for-IT-Solutions"
      );
    },
  },
};
</script>

<style>
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
