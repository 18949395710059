<template>
    <section id="partners" class="client-section"
        v-bind:class="{ 'gray-light-bg': isGray, 'pb-100': paddingBottom, 'pt-100': paddingTop }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>{{ $t("Our_Partners") }}</h2>
                        <p>
                            {{ $t("Our_Partners_explanation") }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12">
                    <carousel :items="1" :nav="false" :autoplay="true" :dots="false" :loop="true" :margin="15"
                        slideTransition="linear" :autoplayTimeout="4000" :autoplaySpeed="false" :smartSpeed="6000"
                        :responsive="{ 0: { items: 2 }, 480: { items: 3 }, 768: { items: 4 }, 992: { items: 5 }, 1200: { items: 6 } }"
                        class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag">

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/MINIO_wordmark.png" alt="partner logo"
                                class="customer-logo" />
                        </div>

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/Microsoft_logo.png" alt="partner logo"
                                class="customer-logo" />
                        </div>

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/Kong_logo.png" alt="partner logo"
                                class="customer-logo" />
                        </div>

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/Veeam_logo.png" alt="partner logo"
                                class="customer-logo" />
                        </div>

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/Grafana_logo.png" alt="partner logo"
                                class="customer-logo" />
                        </div>

                        <div class="item single-customer partner-image-container">
                            <img src="../../../assets/img/customers/alibaba-cloud-logo.png" alt="partner logo"
                                class="customer-logo" />
                        </div>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
    name: "Partners",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false
        },
        paddingTop: {
            type: Boolean,
            default: true
        },
        paddingBottom: {
            type: Boolean,
            default: true
        }
    }
};
</script>
