<template>
    <section class="promo-section ptb-50" v-bind:class="{ 'gray-light-bg': isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-10">
                    <div class="section-heading text-center">
                        <h2 class="font-weight-bold" style="color: #274d9e;">
                            {{ $t("Why") }} <span style="color: #29ade3">{{ $t("MicroBeaver") }}</span>
                            {{ $t("QuestionMark") }}
                        </h2>
                        <p :class="{ rtl: this.$i18n.locale == 'ar' }">
                            {{ $t("ExplanationWhyMicroBeaver") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center justify-content-sm-center">
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body d-flex align-items-center justify-content-center">
                            <div class="row">
                                <div class="col-12">
                                    <span class="fas fa-cubes icon-size-lg" style="color: #274D9E;"></span>
                                </div>
                                <div class="col-12 pt-3">
                                    <h5>{{ $t("ProvenCostSavings") }}</h5>
                                    <!-- <p class="mb-0">
                                            Phosfluorescently target bleeding sources through viral methodsp progressively
                                            expedite empowered.
                                        </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <span class="fas fa-cloud icon-size-lg" style="color: #274D9E;"></span>
                                </div>
                                <div class="col-12 pt-3">
                                    <h5>{{ $t("PrivateAndPublicCloudSupport") }}</h5>
                                    <!-- <p class="mb-0">
                                            Enthusiastically productivate interactive interfaces after
                                            energistically scale client-centered catalysts.
                                        </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <span class="fas fa-check icon-size-lg" style="color: #274D9E;"></span>
                                </div>
                                <div class="col-12 pt-3">
                                    <h5>{{ $t("AvailableOnsiteAndRemotely") }}</h5>
                                    <!-- <p class="mb-0">
                                            Synergistically architect virtual content solutions.
                                            Monotonectally communicate cooperative solutions.
                                        </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <span class="fas fa-hourglass-half icon-size-lg" style="color: #274D9E;"></span>
                                </div>
                                <div class="col-12 pt-3">
                                    <h5>{{ $t("SuccessStoriesWithGovernmentAndCommercialClients") }}</h5>
                                    <!-- <p class="mb-0">
                                            Synergistically architect virtual content solutions.
                                            Monotonectally communicate cooperative solutions.
                                        </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: "WhyMicroBeaver",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
};
</script>