<template>
    <a :href="link">
        <div class="single-team-wrap bg-white text-center border rounded p-4 my-3">
            <img :src="imageUrl" alt="client image" class="img-fluid m-auto" height="300" width="200" />
            <div class="team-content">
                <h5 class="mt-3 pt-3">{{ name }}</h5>
                <!-- <h5 class="mt-3 pt-3">{{ link }}</h5> -->
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "TeamMember",
    props: {
        name: {
            type: String,
            required: true,
        },
        post: {
            type: String,
            required: true,
        },
        imageUrl: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
img {
    height: 80px;
}
</style>