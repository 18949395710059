<template>
    <div class="overflow-hidden">
        <section id="about" class="position-relative overflow-hidden feature-section ptb-100"
            v-bind:class="{ 'gray-light-bg': isGray }">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-md-12 col-lg-12">
                        <div class="feature-contents section-heading">
                            <h2 :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                :class="{ rtl: this.$i18n.locale == 'ar' }">
                                {{ $t("What_do_we_do_for_you") }} {{ $t("QuestionMark") }}
                            </h2>
                            <p :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                :class="{ rtl: this.$i18n.locale == 'ar' }">
                                {{ $t("What_do_we_do_for_you_Explanation") }}
                            </p>

                            <ul :class="{ rtl: this.$i18n.locale == 'ar', 'pt-2': true }"
                                :style="{ ...alignRightInArabic, ...justifyInArabic }">
                                <li class="p-1">
                                    <span class="checkmark">&#10003;</span> <!-- Check mark -->
                                    <strong>{{ $t("Faster_time_to_market") }}</strong>
                                </li>
                                <li class="p-1">
                                    <span class="checkmark">&#10003;</span> <!-- Check mark -->
                                    <strong>{{ $t("Cutting_cost_solutions") }}</strong>
                                </li>
                                <li class="p-1">
                                    <span class="checkmark">&#10003;</span> <!-- Check mark -->
                                    <strong>{{ $t("Latest_technology") }}</strong>
                                </li>
                                <li class="p-1">
                                    <span class="checkmark">&#10003;</span> <!-- Check mark -->
                                    <strong> {{ $t("Up_skilling_your_team") }}</strong>
                                </li>
                                <li class="p-1">
                                    <span class="checkmark">&#10003;</span> <!-- Check mark -->
                                    <strong> {{ $t("years_of_enterprise_experience") }}</strong>
                                </li>
                            </ul>


                            <div class="action-btn mt-4" :style="{ ...alignRightInArabic, ...justifyInArabic }"
                                :class="{ rtl: this.$i18n.locale == 'ar' }">
                                <a href="mailto:info@microbeaver.com" class="btn btn-brand-02 mr-2">
                                    <strong> {{ $t("Contact_us_Now") }}</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutUs",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
        };
    },
    methods: {
    },
};
</script>

<style>
.check-list-wrap li {
    list-style: none;
    /* Remove default list style */
}

.check-list-wrap .checkmark {
    font-size: 1.5em;
    color: #29ade3;
}
</style>
